/* Popup styles */
.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
  }
  
  .popup-content {
    background-color: #fff; 
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
    width: 400px; 
    max-width: 90%; 
  }
  
  .popup-content h2 {
    margin-top: 0; 
  }
  
  .popup-content form div {
    margin-bottom: 15px;
  }
  
  .popup-content label {
    display: block; 
    margin-bottom: 5px; 
  }
  
  .popup-content input {
    width: 100%; /* Full width inputs */
    padding: 10px; /* Padding for inputs */
    border: 1px solid #ddd; /* Border style */
    border-radius: 4px; /* Rounded corners for inputs */
  }
  
  .popup-content button {
    margin-right: 10px; /* Space between buttons */
  }
  .addclient{
    color: #ffffff;
    background: #2196f3;
    border: 1px solid #2196f3;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
    margin: 20px;
  }
  .adduser{
    color: #ffffff;
    background: #2196f3;
    border: 1px solid #2196f3;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
    margin: 20px;
  }
  .addfile{
    margin-top: 10px;
  }
  /* Table styles */
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 10px; 
    text-align: left;
  }
  
  th {
    background-color: #f2f2f2; 
  }
  
  tr:nth-child(even) {
    background-color: #f9f9f9; 
  }
  
  tr:hover {
    background-color: #f1f1f1; 
  }
  .filter-container {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    flex-wrap: wrap;
}

.filter-container .filter-item {
    flex: 1 1 150px;
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .filter-container {
        flex-direction: column;
    }

    .filter-row {
        display: flex;
        gap: 10px;
    }

    .filter-row:first-child {
        margin-bottom: 10px;
    }
    
    .filter-row .filter-item {
        flex: 1 1 50%;
    }
}