.sidebar-container {
  height: 100vh;
  width: 250px;
  background-color: #fff;
  border-right: 1px solid #ccc !important;
  position: fixed !important;
  left: 0;
  top: 0;
  margin-top:74px;
  z-index: 1;
}


.sidebar-container .menu-item.selected-menu-item {
  background-color: #b6d6f8 !important; 
}
.sidebar-container .pro-menu-item:hover {
  background-color: #b6d6f8 !important;
}



