body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f5f5f5;
    margin: 0;
    padding: 0;
    color: #2c3e50;
}

header {
    background-color: #0200FF;
    color: #ffffff;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

header h1 {
    margin: 0;
    font-size: 2rem;  /* Default font size for larger screens */
}

.fullscreen-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
    z-index: 9999; /* Ensure the loader is on top of other content */
  }



.header-logo {
    display: flex;
    align-items: center;
}

.logo-image {
    width: 214px;  /* Default width for laptop screens */
    height: auto;
    margin-right: 15px;
    background-color: #ffffff;  /* Optional: white background */
    padding: 5px;
    border-radius: 8px;
}

.page-title {
    flex-grow: 1;
    text-align: center;
    font-size: 2rem;  /* Default size for the page title */
}

/* User Icon Styles */
.user-icon {
    display: flex;
    align-items: center;
    gap: 10px;
}

.user-icon img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-left: 10px;
}

@media (max-width: 768px) {
    .logo-image {
        width: 120px;  
    }

    .page-title {
        font-size: 1.5rem;  
    }
}

@media (max-width: 480px) {
    .logo-image {
        width: 100px;  
    }

    .page-title {
        font-size: 1.2rem;  
    }
}


