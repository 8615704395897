/* Center the fullscreen loader */
.fullscreen-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
    z-index: 9999; /* Ensure the loader is on top of other content */
  }
  
  .p-field {
    margin-bottom: 1rem;
  }